import React from "react";
import { MdProductionQuantityLimits,MdImportExport,MdShoppingBasket } from "react-icons/md";
import { BiCustomize } from "react-icons/bi";
import { BsCalendar4Event } from "react-icons/bs";
import { SiAltiumdesigner } from "react-icons/si";
function Services() {
  const elements = [
    {
      id: 1,
      name: "Product Sourcing",
      content:
        "We source authentic handcrafted items, souvenirs, and cultural products from around the world",
      icon: <MdProductionQuantityLimits />,
    },
    {
      id: 2,
      name: "Customization",
      content:
        "We offer customization services for our handcrafted items to create a unique piece just for you.",
        icon: <BiCustomize />,
    },
    {
      id: 3,
      name: "Import & Export",
      content:
        "We handle all aspects of import and export for our customers, ensuring safe and efficient shipping.",
        icon: <MdImportExport />,
    },
    {
      id: 4,
      name: "Wholesale",
      content:
        "We offer wholesale pricing for our handcrafted items, with custom orders available. Contact us to learn more.",
        icon: <MdShoppingBasket />,
    },
  ];
  return (
    <section id="services" className="font-poppins py-4 md:py-16 mb-6 px-3 md:px-0">
      <div className="container mx-auto">
        <div className="flex flex-col items-center justify-center pb-8 space-y-3">
          <h1 className="text-3xl md:text-4xl text-primary uppercase font-bold text-center">
            What we offer
          </h1>
          <p className="w-full md:w-2/3 text-center">
          At Choice Handicrafts, we offer a wide range of authentic and unique handcrafted products sourced from around the world. Our product categories include painting goods, decoration items, souvenirs, Buddhist-style paintings, and cultural products. Additionally, we offer customization services for our handcrafted items, import and export services, and wholesale pricing. Whatever your needs, we're here to help you find the perfect handcrafted item to add to your collection.
          </p>
        </div>

        <div className="grid grid-cols-1 gap-5 mt-12 sm:grid-cols-4 lg:mt-20 lg:gap-x-12">
          {elements.map(function (element) {
            const { id, name, content,icon } = element;
            return (

          <div key={id} className="transition-all duration-200 bg-white hover:shadow-lg">
            <div className="py-10 px-9">
             <p className="text-6xl text-primary">{icon}</p>
              <h3 className="mt-8 text-lg font-semibold text-black">
                {name}
              </h3>
              <p className="mt-4 text-base text-gray-700">
                {content}
              </p>
            </div>
          </div>

            );
          })}
        </div>
      </div>
    </section>
  );
}

export default Services;
