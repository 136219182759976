import React from "react";
import about from "../assets/products/3.jpg";
function About() {
  return (
    <section className="font-poppins py-10 bg-white sm:py-16 lg:py-24">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="grid items-center grid-cols-1 lg:grid-cols-2 gap-x-12 xl:gap-x-24 gap-y-12">
          <div className="relative lg:mb-12">
            <img
              className="absolute -right-0 -bottom-8 xl:-bottom-12 xl:-right-4"
              src="https://cdn.rareblocks.xyz/collection/celebration/images/content/3/dots-pattern.svg"
              alt=""
            />
            <div className="pl-12 pr-6">
              <img
                className="relative"
                src={about}
                alt=""
              />
            </div>
            <div className="absolute left-0 pr-12 bottom-8 xl:bottom-20">
              <div className="max-w-xs bg-primary rounded-lg sm:max-w-md xl:max-w-md">
                <div className="px-3 py-4 sm:px-5 sm:py-8">
                  <div className="flex items-start">
                    <p className="text-3xl sm:text-4xl">👋</p>
                    <blockquote className="ml-5">
                      <p className="text-sm font-medium text-white sm:text-lg">
                        “"I recently purchased a beautiful handcrafted vase from choice handicrafts, and I was blown away by its exquisite design and quality. The attention to detail and craftsmanship was truly impressive.”
                      </p>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="2xl:pl-16">
            <h2 className="text-3xl font-bold capitalize leading-tight text-primary sm:text-4xl lg:text-5xl lg:leading-tight">
              We provide the best products and services
            </h2>
            <p className="text-xl leading-relaxed text-gray-900 mt-9">
            Welcome to choice handicrafts, where we specialize in importing and exporting handcrafted items, paintings, and cultural products from various parts of the world. Our collection includes a wide range of home decor, gifts, and souvenirs, all of which are carefully sourced and crafted by local artisans. 
            </p>
            <p className="mt-6 text-xl leading-relaxed text-gray-900">
            Whether you're looking to add a touch of culture to your home or find the perfect gift for someone special, we have something for everyone. Explore our collection today and discover the beauty of authentic handcrafts and cultural treasures.
            </p>
            <br />
            <a href="#contact" className="bg-primary text-white px-6 py-3 rounde">
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
